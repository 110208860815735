import React, { useState } from 'react'
import { Col, Row, Cascader, Button } from 'antd'
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import DebounceInput from '../../../components/DebounceInput'
import { CountryList } from './Location'

interface IAuditorTableOptionsProps {
  firstName: string
  setFirstName: (name: string) => void
  lastName: string
  setLastName: (name: string) => void
  firmName: string
  setFirmName: (name: string) => void
  location: any
  setLocation: any
  setSkip: (skip: number) => void
  setPage: (page: number) => void
}


function AuditorTableOptions(props: IAuditorTableOptionsProps) {
  const { firstName, setFirstName, lastName, setLastName, firmName, setFirmName, location, setLocation, setSkip, setPage } =
    props
    const [show, setShow] = useState(false);
  return (
    <>
    <Row>
      <Col xs={24} md={12} style={{justifySelf:"left"}}>
        <Link to="/add-auditor">{<RightOutlined />}Can&apos;t find what you&apos;re looking for? Add an auditor here </Link>
      </Col>
      <Col xs={24} md={7} style={{textAlign:"right", marginLeft:"auto", marginRight:"0px"}}>
        <Link to="#" onClick={()=>setShow(!show)}>{show ? <DownOutlined /> : <RightOutlined />} Filter</Link>
      </Col>
      </Row>
      {show ? <>
      <Row align="middle">
      <Col xs={24} md={7} style={{ textAlign: 'left', marginRight:'10px'}}>
        <Cascader
          options={CountryList}
          value={location[0]}
          onChange={(value) => {
            setSkip(0);
            setPage(0);
            if(value === undefined){
              setLocation([])
            }else{
              setLocation(value)
            }
          }}
          style={{ width: '100%' }}
          placeholder="Find by Location (case sensitive)"
          changeOnSelect
          showSearch
        />
      </Col>
      <Col xs={24} md={7} style={{ textAlign: 'left'}}>
        <DebounceInput
          placeholder="Find by Firm"
          allowClear
          value={firmName}
          onChange={(e) => {
            setSkip(0);
            setPage(0);
            setFirmName((e.target as HTMLInputElement).value)
          }}
        />
      </Col>
      <Col xs={24} md={2} style={{ marginLeft:'auto', marginRight:'10px'}}>
        <Button onClick={()=>{
          setSkip(0);
          setPage(0);
          setFirstName('')
          setLastName('')
          setFirmName('')
          setLocation([])
        }}>
          Reset
        </Button>
      </Col>
      </Row>
      <Row align="middle">
      <Col xs={24} md={7} style={{ textAlign: 'left', marginRight:'10px'}}>
        <DebounceInput
          placeholder="Find by First Name"
          allowClear
          value={firstName}
          onChange={(e) => {
            setSkip(0);
            setPage(0);
            setFirstName((e.target as HTMLInputElement).value)
          }}
        />
      </Col>
      <Col xs={24} md={7} style={{ textAlign: 'left' }}>
        <DebounceInput
          placeholder="Find by Last Name"
          allowClear
          value={lastName}
          onChange={(e) => {
            setSkip(0);
            setPage(0);
            setLastName((e.target as HTMLInputElement).value)
          }}
        />
      </Col>
      <Col xs={24} md={2} style={{ marginLeft:'auto', marginRight:'10px'}}></Col>
    </Row>
    </>:<></>}
    </>
  )
}

export default AuditorTableOptions
