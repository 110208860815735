import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Divider } from 'antd'
import { FacebookOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons'
// import { GoogleOutlined, MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

function Footer() {
  const shareLink = encodeURIComponent('https://www.rateauditor.org/')

  const shareLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`,
      'ra-share-linkedin',
      'height=500,width=650,modal=yes,alwaysRaised=yes'
    )
  }

  const shareFaceBook = () => {
    window.open(
      `https://www.facebook.com/sharer.php?u=${shareLink}&t=Rate me on RateAuditor.org`,
      'ra-share-facebook',
      'height=750,width=650,modal=yes,alwaysRaised=yes'
    )
  }

  const shareTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${shareLink}&text=Rate me on RateAuditor.org&source=RateAuditorOrg`,
      'ra-share-twitter',
      'height=750,width=650,modal=yes,alwaysRaised=yes'
    )
  }

  return (
    <>
      <Row justify="space-between">
        <Col xs={10} md={6}>
          <h3>About RateAuditor</h3>
          <ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '0' }}>
            <li>
              <Link to="info/importance">Why is this site important?</Link>
            </li>
            <li>
              <Link to="info/aboutus">About Us</Link>
            </li>
            <li>
              <Link to="terms-and-conditions">Terms</Link>
            </li>
            <li>
              <Link to="privacy-policy">Privacy</Link>
            </li>
          </ul>
        </Col>
        <Col xs={10} md={6}>
          <h3>Help Center</h3>
          <ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '0' }}>
            <li>
              <Link to="info/faq">FAQ</Link>
            </li>
            <li>
              <Link to="info/contactus">Contact Us</Link>
            </li>
	    <li>
	      <Link to="sitemap">Sitemap</Link>
	    </li>
            {/* <li><Link to="/support">Support</Link></li>
                        <li><Link to="/feedback">Feedback</Link></li> */}
          </ul>
        </Col>
        <Col xs={24} md={6}>
          <Row justify="center">
            <Col xs={0} md={24}>
              <h3>Share</h3>
            </Col>
            <Col md={24}>
              {/* <Button type="text" icon={<GoogleOutlined style={{ fontSize: "1.5rem" }} />}></Button> */}
              <Button
                type="text"
                icon={<FacebookOutlined style={{ fontSize: '1.5rem' }} />}
                onClick={() => {
                  shareFaceBook()
                }}
              ></Button>
              <Button
                type="text"
                icon={<LinkedinOutlined style={{ fontSize: '1.5rem' }} />}
                onClick={() => {
                  shareLinkedIn()
                }}
              ></Button>
              <Button
                type="text"
                icon={<TwitterOutlined style={{ fontSize: '1.5rem' }} />}
                onClick={() => {
                  shareTwitter()
                }}
              ></Button>
              {/* <Button type="text" icon={<MailOutlined style={{ fontSize: "1.5rem" }} />}></Button> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Divider />
        <p>{`©${dayjs().year()} Rate Audit Partner All rights reserved`}</p>
      </Row>
    </>
  )
}

export default Footer
