import React, { useMemo } from 'react'
import {Button, Card, Col, Row, Modal} from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { auditor as auditorApi } from '@rap/api'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../../redux/hooks'
import { selectLoginStatus, selectUserInfo } from '../../../redux/userSlice'
import { AuditorSummary, LoginForm, VerifyForm } from '../components'
import { ClaimProfileSteps } from './ClaimProfileSteps'
import './ClaimProfile.css' // Add this line to import the CSS file

function ClaimProfile() {
  const [searchParams, setSearchParams] = useSearchParams()

  const loginStatus = useAppSelector(selectLoginStatus)
  const userInfo = useAppSelector(selectUserInfo)
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal();

  const countDown = () => {
    let secondsToGo = 10;
    const instance = modal.success({
      title: 'Your claim has been submitted!',
      content: `This alert will close in ${secondsToGo} seconds. You will be redirected to the auditor page. You will be notified when your claim has been processed.`,
      onOk: () => {
        navigateToAuditorPage();
      },
      onCancel: () => {
        navigateToAuditorPage();
      },
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This alert will close in ${secondsToGo} seconds. You will be redirected to the auditor page. You will be notified when your claim has been processed.`,
      });
    }, 1000);

    const navigateToAuditorPage = () => {
      clearInterval(timer);
      navigate('/auditor/' + auditor?._id);
      instance.destroy();
    };

    setTimeout(() => {
    }, secondsToGo * 1000);
  };

  const submitProfileClaim = () => {
    if (auditor) {
      auditorApi
          .claimAuditorProfile(auditor._id)
          .then((res: any) => {
            console.log('Claimed');
            countDown();
          })
          .catch((error: any) => {
            if (error.response.status === 403) {
              alert('Error: You cannot claim multiple auditor profiles');
            } else {
              console.error('An unexpected error occurred:', error);
            }
          });
    }
  };


  const aid = useMemo(() => {
    return searchParams.get('auditor')
  }, [searchParams])
  const {
    data: auditorData,
    isLoading: isLoadingAuditor,
    isSuccess: isSuccessAuditor,
  } = useQuery(
      [auditorApi.getAuditorInfo.name, aid],
      () => {
        return auditorApi.getAuditorInfo(aid!)
      },
      {
        enabled: aid !== null,
      }
  )
  const auditor = useMemo(() => {
    if (isSuccessAuditor) {
      return auditorData.data.auditor as rateauditor.api.auditor.IAuditorInfo
    }
  }, [auditorData, isSuccessAuditor])

  return (
      <div>
        <Row gutter={[20, 20]} className="equal-height-row">
          <Col xs={24} lg={12} className="equal-height-col">
            <Card bordered={false} className="vertical-center-card">
              <div className="center-content">
                <AuditorSummary
                    loading={isLoadingAuditor}
                    currentAuditor={auditor}
                    title={'The profile you are trying to claim:'}
                    allowClaim={false}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12} className="equal-height-col">
            <Card bordered={false} className="vertical-center-card">
              <div className="center-content">
                <ClaimProfileSteps />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24}>
            <Card bordered={false} className="vertical-center-card">
              <p>
                <strong>Claim Profile:</strong> This is the profile you are trying to claim. Your information will be manualy
                verified by our team. Please make sure the information in your account is accurate. If your information does not match
                the selected auditors information your claim will be denied. Once the claim is verified you will recieve email notifications when you recieve a rate.
                You will also be able to reply to rates.
              </p>
              <Button type="primary" style={{ width: '50%', }} onClick={submitProfileClaim}>
                Submit Profile Claim
              </Button>
              {contextHolder}
            </Card>
          </Col>
        </Row>
      </div>
  )
}

export default ClaimProfile
