import React from 'react';
import { Typography, Row, Col, Collapse, Card, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import faqList from './faqList.json';
import '../info/FAQ.css';

const { Title, Paragraph } = Typography;

function Importance() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/info/faq",
    "dateModified": "2024-08-13T18:32:28+01:00",
    "priority": "0.6"
  }
  return (
    <>
    <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
    <div className="faq-container">
      <Card className="faq-card" bordered={false}>
        <Row justify="center">
          <Col xs={24} lg={16}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Title level={2} className="faq-title">What is the importance of this site?</Title>
              <Collapse 
                accordion 
                expandIconPosition="right" 
                className="faq-collapse"
                ghost
              >
                {faqList.faq.map((element, index) => (
                  <Collapse.Panel 
                    header={element.q} 
                    key={index} 
                    className="faq-panel"
                  >
                    <Paragraph className="faq-paragraph">
                      <ReactMarkdown>{element.a}</ReactMarkdown>
                    </Paragraph>
                  </Collapse.Panel>
                ))}
              </Collapse>
              <Paragraph className="faq-contact">
              By providing a platform for public ratings and feedback, we can drive improvements in audit quality, enhance transparency and accountability, and build greater trust in financial reporting. Your participation in rating auditors can make a significant difference in achieving these goals.
              </Paragraph>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
    </>
  );
}

export default Importance;
