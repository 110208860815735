import React, { useState } from 'react'
import { Alert, Checkbox, CheckboxProps, Button, Form, Input, Result,  } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined, MailTwoTone } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import { validator as passwordValidator } from '../utils/PasswordValidate'

import { user as userApi } from '@rap/api'
import ReCAPTCHA from '../components/ReCAPTCHA'

const Signup = () => {
  const structuredData = { 
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/signup",
    "dateModified": "2024-05-28T18:32:26+01:00",
    "priority": "0.8"
  }
  const [isSuccess, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [alartSignup, setAlartSignup] = useState<rateauditor.general.IErrorMessage | null>(null)

  const handleSignup = (data: rateauditor.api.user.ISignupData) => {
    setLoading(true)
    userApi.postSignup(data).then((res) => {
      if (res.data.status === 'success') {
        setSuccess(true)
      } else {
        setAlartSignup(res.data.error)
      }
      setLoading(false)
    })
  }


  if (isSuccess) {
    return (
      <>
      <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
      <div className="max-w-2xl mx-auto bg-white shadow-md p-6">
        <Result icon={<MailTwoTone />} title="A confirmation email has been sent to you mailbox" />
      </div>
      </>
    )
  }

  const [checked, setChecked] = useState(false);

  return (
    <>
    <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>	
  </Helmet>
    <div className="max-w-lg mx-auto bg-white shadow-md p-6">
      <div className="mb-6">
        <h2 className="font-semibold text-3xl mb-1">Create an Account</h2>
        <div>
          <span className="mr-1">Already have an account?</span>
          <Link to="/login">Log in</Link>
        </div>
      </div>
      <div>
        <Form name="signup" onFinish={handleSignup}>
          {alartSignup ? (
            <Form.Item>
              <Alert type="error" message={alartSignup.message} showIcon />
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
            hasFeedback
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              { required: true, message: 'Please input your username!' },
              ({ getFieldValue }) => ({
                validator(rule, value: string) {
                  if (!value) {
                    return Promise.resolve()
                  } else if (value.length < 8 || value.match(/\W/)) {
                    return Promise.reject(
                      new Error('Use 8 or more characters with a mix of letters & numbers.')
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            name="first_name"
            rules={[{ required: true, message: 'Please input your first name!' }]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First name"
            />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[{ required: true, message: 'Please input your last name!' }]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Last name"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              {validator: passwordValidator}
            ]}
            hasFeedback
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  )
                },
              }),
            ]}
            hasFeedback
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item
            name="agree"
            valuePropName="checked"
            rules={[
              { required: true},
            ]}
          >
            <Checkbox  checked={checked} onChange={()=>{setChecked(!checked)}}><p style={{ fontSize: '0.75rem', color: '#666' }}>
              I agree to the {' '}
              <a href="https://drive.google.com/file/d/1QV7ZRuOURFQDf4qAU8349d42vuIO9Gu8/view?usp=drive_link" rel="noreferrer noopener" target="_blank">
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://drive.google.com/file/d/1CoGtXfAcskbM9e8NDCV4nTjOIk6j37Nv/view?usp=drive_link"
                rel="noreferrer noopener"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p></Checkbox>
          </Form.Item>

          <Form.Item>
            <Button disabled={!checked} type="primary" loading={isLoading} htmlType="submit" block>
              {checked ? <>Signup</> : <>Click Agree To Signup</>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    </>
  )
}

export default Signup
